html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #194847;
}

.action {
  background-color: #533fba;
  color: #FFF;
  border-radius: 10px;
  border-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  font-family: 'Fira Sans';
}

.action:hover {
  background-color: #8167ff;
}

.center {
  text-align: center;
}

.landing-top-section {
  height: calc(100vh - 115px);
}

.caption-dark{
  color: #194847;
}

/*
  Box shadow hover
*/
.box-hoverable {
  transition: box-shadow .3s;
}

.box-hoverable:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}


/*
  Thumb config
*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #194847;
}

::-webkit-scrollbar-thumb {
  background: #3eb3b2;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4fdfdc;
}



/*
anim
*/
@keyframes pulse {
  0% {
    -moz-box-shadow: 0px 0px 0px #7f65ff; 
    box-shadow: 0px 0px 0px #7f65ff; 
  }
  50% {
      -moz-box-shadow: 0px 0px 30px #7f65ff; 
      box-shadow: 0px 0px 30px #7f65ff; 
  }
  100% {
      -moz-box-shadow: 0px 0px 0px #7f65ff;  
      box-shadow: 0px 0px 0px #7f65ff;  
  }
}