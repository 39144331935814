.landing-doc-section {
    background-color: #FFF;
    color: #194847;
    height: 100vh;
    width: 100vw;
}

.landing-doc-section h2 {
    text-align: center;
}

.doc-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.doc-list-card {
    width: 15vw;
    min-width: 230px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #e5ecec;
    border-radius: 15px;
    border: 1px solid #3eb3b2;
}

.doc-list-card h3,h4,h5{
    text-align: center;
}

.doc-list-card img {
    width: 100%;
    aspect-ratio: 23/24;
    border-radius: 10px;
}
.doc-list-card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

#doctors h2 {
    margin-bottom: 5vh;
}

.doc-about-wrapper {
    padding-left: 2vw;
    padding-right: 2vw;
    height: 300px;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}


@media (max-width: 1030px) {
    #doctors{
        height: auto;
    }
    .doc-list{
        flex-direction: column;
    }
    .doc-list-card{
        width: calc(100% - 2px);
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 10px;
    }
    .doc-about-wrapper {
        display: none;
    }
}