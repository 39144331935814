.landing-top-section{
    max-height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 1030px) {
    .landing-top-section{
        height: auto;
    }
}