.carousel-item{
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	min-width: 100vw;
	height: 100%;
	position: relative;
}

.carousel-image {
	object-fit: cover;
	min-width: 100vw;
	max-height: 100%;
}

.item1 {
	background-color: coral;
}

.item2 {
	background-color: cornflowerblue;
}

.item3{
	background-color: darkcyan;
}
/*

*/
.main-container {
	width: 100vw;
	aspect-ratio: 2.3/1;
	overflow: hidden;
	display: flex;
	align-items: center;
	position: relative;
	box-shadow: 0 0 15px 0 #000;
}

.window {
	height: 100%;
	width: 100%;
}

.items-container{
	height: 100%;
	display: flex;
	width: 100vw;

	transition: translate;
	transition-property: transform;
	transition-duration: 500ms;
	transition-timing-function: ease-in-out;
}

/*

*/

.arrow {
	cursor: pointer;
	z-index: 100;
	position: absolute;
}

.arrow:first-of-type {
	left: 5px;
}

.arrow:last-child {
	right: 25px;
}

/*

*/

.carousel-item-card{
	box-sizing: border-box;
	position: absolute;
	width: 25.5vw;
	aspect-ratio: 2.3/1;
	background-color: #ffffffbb;
	background-image: linear-gradient(0deg, #00000000, #ffffffbb);
	backdrop-filter: blur(10px);;
	color: #194847;
	font-family: 'Open Sans', sans-serif;
	border-radius: 10px;
	padding: 1vw 1vw 1vw 1vw;
	user-select: auto !important;
	z-index: 101;
	display: flex;
	flex-direction: column;
}

.card-left {
	left: 3vw;
}

.card-two {
	left: 4vw;
	top: 10vw;
	width: 35vw;
	justify-content: center;
	aspect-ratio: 3/1;
}

.carousel-item-card h1, .carousel-item-card h2, .carousel-item-card h3, .carousel-item-card h4{
	background-color: #194847;;
	background-image: linear-gradient(45deg, #2d817f, #194847);
	background-size: 100%;
	background-repeat: repeat;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
	text-align: center;
}

.carousel-item-card h1 {
	  font-size: 28px;
}

.carousel-item-card h2 {
	font-size: 26px;
	margin-bottom: 2%;
}
.carousel-item-card h3{
	font-size: 24px;
	margin-top: 2%;
	margin-bottom: 2%;
}

.carousel-item-card h4{
	font-size: 22px;
	margin-top: 1%;
}

.carousel-item-card p{
	text-align: center;
	font-size: 18px;
}

.carousel-item-card button {
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	font-size: 18px;
}

/*

*/

@media (max-width: 1030px) {
	.main-container{
		margin-top: 15px;
		height: calc(100vh - 130px);
	}
	.carousel-image {
		object-fit: cover;
		height: 100%;
	}
	.carousel-item-card {
		width: 70vw;
	}
	.carousel-item-card h1{
		font-size: 20px;
	}
	.carousel-item-card h2 {
		font-size: 18px;
	}
	.carousel-item-card h3 {
		font-size: 16px
	}
	.carousel-item-card h4 {
		font-size: 14px;
	}
	.carousel-item-card p {
		font-size: 12px;
	}
}