.services-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.services-grid{
    display: grid;
    column-gap: 2vw;
    row-gap: 2.5vh;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.services-grid-button{
    grid-column: span 3;
    text-align: center;
    z-index: 100;
}

.services-grid-button button {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 5vh;
    font-size: 18px;
    animation: pulse 3s infinite;    
}

#services {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding-top: 0px;
    background-color: #194847;
    position: relative;
    padding-bottom: 50px;
    height: auto;
}

.services-grid-card{
    border-radius: 10px;
}

#services h3 {
    text-align: center;
}

#services h2 {
    margin-top: 0;
    margin-bottom: 13vh;
    text-shadow: 0px 0px 5px #3eb3b2;
}

.services-grid-card img {
    width: 30vw;
    max-width: 500px;
    min-width: 310px;
}

.divider-bottom{
    width: 100vw;
    position: absolute;
    bottom: -20px;
    left: 0px;
    border-radius: 0px;
    pointer-events: none;
}

.price-list {
    height: 80vh;
    width: 80vw;
    max-width: 1280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    padding-bottom: 20px;
}

.price-list table {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.price-list table tr:nth-child(even) {
    background-color: #1948472c;
}

.pricelist-price{
    width: 100px;
}

@media (max-width: 1030px) {
    .services-grid{
        display: flex;
        flex-direction: column;
    }
    #services{
        height: auto;
    }
    #services h2{
        margin-bottom: 80px;
    }
    .price-list{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100vh;
        width: 100vw;
    }
    .services-grid-button button {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 18px;
    }
    .services-grid-card img{
        width: 95vw;
    }
}