.navbar {
    background-image: url(/src/images/raster/texture.jpg);
    background-repeat: repeat;
    background-size: inherit;
    background-attachment: fixed;
    background-position: 50% 0;
    background-color: #3eb3b2;
    color: #FFF;
    display: flex;
    flex-direction: column;
    height: 110px;
    padding-top: 5px;
    z-index: 100;
    box-shadow: 0 0 10px 0 #000;
    position: relative;
}

/*
    Верхняя секция
*/
#mobile-logo{
    display: none;
}

.navbar-topsection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.navbar-topsection-logo{
    margin-left: 4vw;
}

.inline-img {
    display: inline;
    padding: 0;
    vertical-align: baseline;
}

.credentials-adress{
    margin-right: 4vw;
}

.credentials-adress-metro{
    font-size: 20px;
}

.credentials-adress-metro-img{
    width: 20px;
}

.img-novoslobodskaya{
    filter: brightness(0) saturate(100%) invert(34%) sepia(83%) saturate(414%) hue-rotate(347deg) brightness(91%) contrast(82%);
}

.img-dostoevskaya{
    filter: brightness(0) saturate(100%) invert(79%) sepia(32%) saturate(4084%) hue-rotate(28deg) brightness(96%) contrast(99%);
}

.credentials-phone{
    font-size: 22px;
}

.credentials-phone-text{
    margin-bottom: 5px;
}

.credentials-phone a{
    color: #FFF;
    cursor: pointer;
    text-decoration: none;
}

.credentials-phone img{
    width: 22px;
    margin-right: 5px;
}

.credentials-phone-ordercall{
    text-align: center;
}
.credentials-phone-ordercall .action{
    padding-left: 30px;
    padding-right: 30px;
    animation: pulse 3s infinite;
}

.credentials-adress-exact{
    text-align: center;
}
/*
    Нижняя секция
*/

.navbar-navigation{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-inline-start: 0px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
    margin-bottom: 0px;
    margin-top: 5px;
}

.navbar-navigation li{
    display: inline;
    cursor: pointer;
}

/*
    Форма заказа звонка
*/

.order-call-wrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000079;
    z-index: 200;
}

.order-call-form{
    background-color: #FFF;
    width: 800px;
    height: 400px;
    position: fixed;
    left: calc(50vw - 400px);
    top: calc(50vh - 200px);
    z-index: 201;
    border-radius: 25px;
    padding-top: 10px;
    padding-left: 10px;
    box-sizing: content-box;
}

.order-call-form-close{
    border: none;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    width: 48px;
    height: 48px;
}

.order-call-form-close img{
    width: 48px;
}

.order-call-form form {
    display: flex;
    flex-direction: column;
}

.order-call-form form input {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 12px 20px;
    border: none;
    border-bottom: 1px solid #194847;
    box-sizing: border-box;
}

.order-call-form form input:focus{
    outline-width: 0;
    border: none;
    border-bottom: 2px solid #3eb3b2;
}

.order-call-form form .action {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

#errors {
    color: red;
}

@media (max-width: 1030px) {
    .navbar-topsection{
        justify-content: space-around;
    }
    .credentials-phone{
        font-size: 14px;
    }
    .credentials-adress{
        display: none;
    }
    .inline-img{
        display: none;
    }
    .navbar-topsection-logo{
        display: none;
    }
    .order-call-form {
        width: 100vw;
        height: 100vh;
        left: 0px;
        top: 0px;
        padding-top: 40px;
    }
    #mobile-logo{
        display: block;
        position: absolute;
        left: 5px;
        top: 15px;
        width: 95px;
        height: 53px;
    }
}