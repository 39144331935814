.about-mapwrapper {
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.about-text {
    text-align: center;
}

.about-mapwrapper iframe,img {
    border: none;
    border-radius: 10px;
}

#contacts .about-text a {
    color: #3eb3b2;
    text-decoration: none;
}

@media (max-width: 1030px) {
    #contacts{
        height: auto;
    }
    .about-mapwrapper{
        flex-direction: column;
    }
    .about-mapwrapper iframe{
        width: 100%;
        aspect-ratio: 1/1;
    }

    #contacts .about-text {
        text-align: center;
    }
    
}