.footer {
    background-color: #3eb3b2;
    background-image: url(/src/images/raster/texture.jpg);
    background-size: contain;
    color: #FFF;
    height: 300px;
    padding-top: 5px;
    box-shadow: 0 0 10px 0 #000;
    display: flex;
    justify-content: center;
}

.footer-wrapper{
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.footer-section{
    max-width: 30%;
}

.list-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-disclaimer{
    font-size: .8rem;
}

.stanisgrox-link a{
    color: #FFF;
    text-decoration: none;
    cursor: pointer;
}

.stanisgrox-link:hover, .stanisgrox-link a:hover{
    text-shadow: 0px 1px 20px #fff;
}

@media (max-width: 1030px) {
    .footer-wrapper{
        display: none;
    }
}