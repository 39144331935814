#about{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-sizing: content-box;
    padding-top: 10px;
    height: auto;
    position: relative;
    padding-bottom: 8vh;
}

#about .about-text{
    max-width: 1920px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.divider-top {
    position: absolute;
    top: 0px;
    border-radius: 0px;
    left: 0px;
    width: 100vw;
    height: 50vh;
    object-fit: cover;
    pointer-events: none;
    user-select: none;
    z-index: 0;
}

.caption-bright{
    color: #FFF;
    margin-bottom: 10vh;
    width: 100%;
    z-index: 10;
    position: absolute;
    left: auto;
}

.about-overview {
    display: flex;
    justify-content: space-around;
}

.about-overview-card {
    width: 20vw;
}

.about-panorama{
    width: 100%;
    border-radius: 0px;
    margin-bottom: 10px;
}

.about-text {
    padding-left: 5vw;
    padding-right: 5vw;
}
#about .about-text a{
    text-decoration: none;
    color: #3eb3b2;
}

.about-overview-img img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.reviews-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vh;
}

.review-ico {
    width: 5vw;
}

.reviews-card-image {
    display: flex;
    justify-content: center;
}

.reviews-card-image img{
    border-radius: 0px;
}

.reviews-card-text a{
    color: #194847;
    text-decoration: none;
    z-index: 400;
    cursor: pointer;
}

@media (max-width: 1030px) {
    #about{
        height: auto;
        padding-bottom: 30px;
        background-position: top;
        background-size: 200vw;
    }
    .about-text {
        padding-left: 1vw;
        padding-right: 1vw;
        text-align: justify;
    }
    .about-overview{
        flex-direction: column;
    }
    .about-overview-card{
        width: 100%;
    }
    .reviews-row{
        flex-direction: column;
    }
    .reviews-card:last-child{
        margin-top: 30px
    }
    .review-ico {
        width: 20vw;
    }
}